import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaTwitter } from 'react-icons/fa';
import { Menu, X } from 'lucide-react';
import { SignedIn, SignedOut, SignInButton, UserButton, useUser, useAuth } from "@clerk/clerk-react";

const Layout = ({ children }) => {
  // State to manage dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { user } = useUser(); // Retrieve user data from Clerk
  const { isSignedIn } = useAuth(); // Check if the user is signed in
  const [credits, setCredits] = useState(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (user) {
       // Get the Clerk userId

      // Fetch credits with the userId as a parameter
      fetch(`https://getworksheet-backend.vercel.app/api/credits/${user.id}`)
        .then(response => response.json())
        .then(data => setCredits(data.credits))
        .catch(error => console.error('Error fetching credits:', error));
    }
  }, [user]);

  return (
    <div className="flex flex-col min-h-screen font-inter text-gray-900">
      <header className="relative">
        <div className="container mx-auto px-4 py-4">
          <nav className="relative">
            {/* Main Navigation Bar */}
            <div className="flex justify-between items-center">
              {/* Logo */}
              <Link to="/" className="flex items-center">
                <span className="ml-2 text-xl font-semibold">Get Worksheet</span>
              </Link>

              {/* Desktop Navigation */}
              <div className="hidden md:flex items-center gap-4">
                <Link
                  to="/worksheet-generator"
                  className="px-4 py-2 rounded-xl bg-[#6B65E0] text-white hover:bg-[#5953c3] transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  Create worksheet
                </Link>
                {(isSignedIn && (credits !== null)) && <h1>
                  {credits} credits left
                </h1>}
                <SignedOut>
                  <SignInButton mode="modal">
                    <button className="px-4 py-2 rounded-xl bg-white text-gray-800 border border-[#E5E5E5]">
                      Sign In
                    </button>
                  </SignInButton>
                </SignedOut>
                <SignedIn>
                  <UserButton
                    afterSignOutUrl="/"
                    appearance={{
                      elements: {
                        avatarBox: "w-8 h-8"
                      }
                    }}
                  />
                </SignedIn>
              </div>

              {/* Mobile Menu Button */}
              <button
                className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                aria-label="Toggle menu"
              >
                {isDropdownOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </button>
            </div>

            {/* Mobile Dropdown Menu */}
            <div
              ref={dropdownRef}
              className={`absolute right-0 left-0 mt-2 md:hidden bg-white border border-gray-200 rounded-lg shadow-lg transform transition-all duration-200 ease-in-out z-50 ${
                isDropdownOpen
                  ? 'opacity-100 translate-y-0'
                  : 'opacity-0 -translate-y-4 pointer-events-none'
              }`}
            >
              <div className="p-4 space-y-3">
                <Link
                  to="/worksheet-generator"
                  className="block w-full px-4 py-2 text-center rounded-md bg-[#6B65E0] text-white hover:bg-[#5953c3] transition duration-300"
                  onClick={() => setIsDropdownOpen(false)}
                >
                  Create worksheet
                </Link>
                <div className='flex items-center justify-center'>{(isSignedIn && (credits !== null)) && <h1 className='text-lg'>
                  {credits} credits left
                </h1>}</div>
                <div className="pt-2 border-t border-gray-100">
                  <SignedOut>
                    <SignInButton mode="modal">
                      <button 
                        className="w-full px-4 py-2 text-center rounded-md border border-[#6B65E0] text-[#6B65E0] hover:bg-[#6B65E0] hover:text-white transition duration-300"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        Sign In
                      </button>
                    </SignInButton>
                  </SignedOut>
                  <SignedIn>
                    <div className="flex justify-center">
                      <UserButton
                        afterSignOutUrl="/"
                        appearance={{
                          elements: {
                            avatarBox: "w-8 h-8"
                          }
                        }}
                      />
                    </div>
                  </SignedIn>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <main className="flex-grow bg-[#FAFFFB]">
        {children}
      </main>

      <footer className="bg-gray-900 text-white">
        <div className="container mx-auto px-4 py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">About us</h3>
              <p className="text-gray-400">
                Empowering educators with easy-to-use tools for creating engaging worksheets.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Quick links</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/about" className="text-gray-400 hover:text-white transition duration-300">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-gray-400 hover:text-white transition duration-300">
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="text-gray-400 hover:text-white transition duration-300">
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Connect with us</h3>
              <div className="flex space-x-4">
                <a
                  href="https://x.com/worksheet23194"
                  className="text-gray-400 hover:text-white transition duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter size={24} />
                </a>
              </div>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
            <p>&copy; 2024 Worksheet Generator. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;